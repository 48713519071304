import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import { DateField } from "@mui/x-date-pickers";

// ----------------------------------------------------------------------

export default function RHFDateField({ name, helperText, type, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DateField
          {...field}
          fullWidth
          value={field.value}
          onChange={(event) => {
            field.onChange(event.target.value);
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}

RHFDateField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
};
